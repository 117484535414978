import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";

const useStyles = makeStyles(styles);

export default function SectionNotifications() {
  const classes = useStyles();
  return (
    <div className={classes.section} id="notifications">
      <div className={classes.container}>
        <div className={classes.title}>
          <h3></h3>
        </div>
      </div>

      <SnackbarContent
            message={
              <span>
                <b>INFO ALERT: </b> Currently, this program is customized for a few certain robots. Only units with the first name (at base of robot ~ FIRSTNAME - XXXX - XXXX - XXXX): <u>Orchid, Mauve, Fossil, Sepia, Cyan, Snow or Ghost, </u>are able to run this program.
              </span>
            }
            close
            color="warning"
            icon="info_outline"
          />


      <Clearfix />
    </div>
  );
}
