import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {TextField} from "@material-ui/core";

function App(){
const getData = (e)=>{
  console.warn (e.target.value);
}

return(

  <div className = "App">
  <h1>TextField</h1>
  <TextField
label='enter name'
color='secondary'
variant='outlined'
onChange={getData}
  />

  </div>

)
}
export default App;
